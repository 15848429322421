/* You can add global styles to this file, and also import other style files */
@import 'assets/fontawesome/css/all.css';
@import 'assets/dark/darktheme_v2.0.2.css';
@import 'assets/css/table-fixed.css';
@import 'assets/css/background-light.css';

@font-face {
  font-family: 'Varela Round';
  src: url(assets/fonts/VarelaRound-Regular.ttf);
}

* {
  font-family: 'Varela Round', Helvetica, sans-serif !important;
}

@import '../node_modules/bootstrap/scss/bootstrap.scss';