/* CUSTOM */
[data-theme="dark"] {
  color: #eee;
  background-color: #010101;
  /* OBSOLETE IN BOOTSTRAP 5 due to .dropdown-menu-dark */
  /* .dropdown-menu {
    background-color: $form-bg;
    border-color: $form-border;
  }

  .dropdown-item:not(:disabled):not(.disabled) {
    color: $form-color;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: $form-hover-bg;
  }

  .dropdown-divider {
    border-top-color: $form-border;
  } */
  /* OBSOLETE IN BOOTSTRAP 5 */
  /* .custom-select {
    color: $form-color;
    background-color: $form-bg;
    border-color: $form-border;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  } */
  /* OBSOLETE IN BOOTSTRAP 5 */
  /* .breadcrumb {
    background-color: $form-bg;
    border-color: $form-border;
  } */
  /* .close {
		color: $color;
		opacity: 1;
	} */
  /* CUSTOM */
  /* hr Divider */
  /* Muted Text */
  /* Dropdown */
  /* Dropdown menu toggle outline and box-shadow removal */
  /* Dropdown active no hover overwrite */
  /* Input readonly */
  /* Modal Close */
  /*  OBSOLETE IN BOOTSTRAP 5 due to .btn-close
   .modal-header .close {
    color: $color;
  } */
  /* Toasts */
  /* Default td white/black */
  /* BOOTSTRAP 5 */
  /* Accordions */
}

[data-theme="dark"] .bg-darkmode-dark {
  background-color: #343a40 !important;
}

[data-theme="dark"] .bg-darkmode-light {
  background-color: #f8f9fa !important;
}

[data-theme="dark"] pre {
  color: #eee;
}

[data-theme="dark"] img.icon,
[data-theme="dark"] svg.icon {
  -webkit-filter: invert(1);
          filter: invert(1);
}

[data-theme="dark"] .table {
  color: #eee;
  background-color: #111;
}

[data-theme="dark"] .table-striped > tbody > tr:nth-of-type(odd) {
  color: #eee;
  background-color: #010101;
}

[data-theme="dark"] .table-hover tbody tr:hover {
  color: white;
  background-color: #010101;
}

[data-theme="dark"] .list-group-item {
  color: #eee;
  background-color: #010101;
  border-color: #404040;
}

[data-theme="dark"] .list-group-item.list-group-item-action:focus, [data-theme="dark"] .list-group-item.list-group-item-action:hover {
  background-color: #010101;
}

[data-theme="dark"] .list-group-item.active, [data-theme="dark"] .list-group-item.active:focus, [data-theme="dark"] .list-group-item.active:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

[data-theme="dark"] .list-group-item-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

[data-theme="dark"] .list-group-item-primary.list-group-item-action:focus, [data-theme="dark"] .list-group-item-primary.list-group-item-action:hover {
  color: #fff;
  background-color: #0069d9;
}

[data-theme="dark"] .list-group-item-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

[data-theme="dark"] .list-group-item-secondary.list-group-item-action:focus, [data-theme="dark"] .list-group-item-secondary.list-group-item-action:hover {
  color: #fff;
  background-color: #5a6268;
}

[data-theme="dark"] .list-group-item-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

[data-theme="dark"] .list-group-item-success.list-group-item-action:focus, [data-theme="dark"] .list-group-item-success.list-group-item-action:hover {
  color: #fff;
  background-color: #218838;
}

[data-theme="dark"] .list-group-item-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

[data-theme="dark"] .list-group-item-danger.list-group-item-action:focus, [data-theme="dark"] .list-group-item-danger.list-group-item-action:hover {
  color: #fff;
  background-color: #c82333;
}

[data-theme="dark"] .list-group-item-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

[data-theme="dark"] .list-group-item-warning.list-group-item-action:focus, [data-theme="dark"] .list-group-item-warning.list-group-item-action:hover {
  color: #212529;
  background-color: #e0a800;
}

[data-theme="dark"] .list-group-item-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

[data-theme="dark"] .list-group-item-info.list-group-item-action:focus, [data-theme="dark"] .list-group-item-info.list-group-item-action:hover {
  color: #fff;
  background-color: #138496;
}

[data-theme="dark"] .list-group-item-light {
  color: #212529;
  background-color: #6c757d;
  border-color: #6c757d;
}

[data-theme="dark"] .list-group-item-light.list-group-item-action:focus, [data-theme="dark"] .list-group-item-light.list-group-item-action:hover {
  color: #212529;
  background-color: #e2e6ea;
}

[data-theme="dark"] .list-group-item-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

[data-theme="dark"] .list-group-item-dark.list-group-item-action:focus, [data-theme="dark"] .list-group-item-dark.list-group-item-action:hover {
  color: #fff;
  background-color: #23272b;
}

[data-theme="dark"] .jumbotron {
  color: #eee;
  background-color: #111;
  border-color: #404040;
}

[data-theme="dark"] .card {
  color: #eee;
  background-color: #010101;
  border-color: #404040;
}

[data-theme="dark"] .card-header,
[data-theme="dark"] .card-footer {
  color: #eee;
  background-color: #111;
  border-top-color: #404040;
  border-bottom-color: #404040;
}

[data-theme="dark"] .form-control {
  color: white;
  background-color: #111;
  border-color: #404040;
}

[data-theme="dark"] .form-control-plaintext {
  color: white;
}

[data-theme="dark"] .custom-file-label {
  color: white;
  background-color: #111;
  border-color: #404040;
}

[data-theme="dark"] .custom-file-label:after {
  color: #eee;
  background-color: #222;
}

[data-theme="dark"] .input-group-text {
  color: #eee;
  background-color: #222;
  border-color: #404040;
}

[data-theme="dark"] .page-item .page-link {
  border-color: #404040;
}

[data-theme="dark"] .page-item.disabled .page-link {
  background-color: #111;
  color: white;
}

[data-theme="dark"] .page-item:not(.active) .page-link {
  background-color: #111;
}

[data-theme="dark"] .page-item:not(.active) .page-link:hover {
  background-color: #010101;
}

[data-theme="dark"] .nav-tabs {
  border-bottom-color: #404040;
}

[data-theme="dark"] .nav-tabs .nav-link:focus,
[data-theme="dark"] .nav-tabs .nav-link:hover {
  background-color: #010101;
  border-color: #404040;
}

[data-theme="dark"] .nav-tabs .nav-item.show .nav-link,
[data-theme="dark"] .nav-tabs .nav-link.active {
  color: #eee;
  background-color: #010101;
  border-color: #404040;
  border-bottom-color: #010101;
}

[data-theme="dark"] .nav-tabs.card-header-tabs .nav-item.show .nav-link,
[data-theme="dark"] .nav-tabs.card-header-tabs .nav-link.active {
  background-color: #010101;
  border-bottom-color: #010101;
}

[data-theme="dark"] .popover {
  background-color: #010101;
  border-color: #404040;
}

[data-theme="dark"] .popover-body {
  color: #eee;
}

[data-theme="dark"] .popover-header {
  background-color: #111;
  border-bottom-color: #404040;
}

[data-theme="dark"] .bs-popover-top > .arrow::before,
[data-theme="dark"] .bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #404040;
}

[data-theme="dark"] .bs-popover-top > .arrow::after,
[data-theme="dark"] .bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #010101;
}

[data-theme="dark"] .bs-popover-right > .arrow::before,
[data-theme="dark"] .bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #404040;
}

[data-theme="dark"] .bs-popover-right > .arrow::after,
[data-theme="dark"] .bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #010101;
}

[data-theme="dark"] .bs-popover-bottom > .arrow::before,
[data-theme="dark"] .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #404040;
}

[data-theme="dark"] .bs-popover-bottom > .arrow::after,
[data-theme="dark"] .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #010101;
}

[data-theme="dark"] .bs-popover-left > .arrow::before,
[data-theme="dark"] .bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #404040;
}

[data-theme="dark"] .bs-popover-left > .arrow::after,
[data-theme="dark"] .bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #010101;
}

[data-theme="dark"] .progress {
  background-color: #111;
  border-color: #404040;
}

[data-theme="dark"] .modal-header,
[data-theme="dark"] .modal-footer {
  color: #eee;
  background-color: #111;
  border-bottom-color: #404040;
  border-top-color: #404040;
}

[data-theme="dark"] .modal-content {
  color: #eee;
  background-color: #010101;
  border-color: #404040;
}

[data-theme="dark"] hr {
  border-color: grey;
}

[data-theme="dark"] .text-muted {
  color: #888 !important;
}

[data-theme="dark"] .dropdown-toggle:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

[data-theme="dark"] .navbar-nav > li > .dropdown-menu .active {
  color: white !important;
  background-color: #0d6efd !important;
}

[data-theme="dark"] .input-readonly {
  color: #ccc;
  background-color: #222;
}

[data-theme="dark"] .toast {
  background-color: #111;
  opacity: 0.85;
}

[data-theme="dark"] .toast-header {
  background-color: #222;
  color: #bbb;
}

[data-theme="dark"] .table-default {
  background-color: #010101 !important;
}

[data-theme="dark"] ::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
}

[data-theme="dark"] .form-select {
  color: white;
  background-color: #111;
  border-color: #404040;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

[data-theme="dark"] .form-control:disabled, [data-theme="dark"] .form-select:disabled, [data-theme="dark"] .form-control:-moz-read-only {
  color: #ccc !important;
  background-color: #222 !important;
}

[data-theme="dark"] .form-control:disabled, [data-theme="dark"] .form-select:disabled, [data-theme="dark"] .form-control:read-only {
  color: #ccc !important;
  background-color: #222 !important;
}

[data-theme="dark"] .modal-header .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23eee'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

[data-theme="dark"] .toast-header .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23eee'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

[data-theme="dark"] .background-light {
  color: #eee;
  background-color: #111;
  border-color: #404040;
}

[data-theme="dark"] .accordion-item {
  color: #eee;
  background-color: #010101;
  border-color: #404040;
}

[data-theme="dark"] .accordion-button {
  color: #eee;
  background-color: #111;
  border-top-color: #404040;
  border-bottom-color: #404040;
}

[data-theme="dark"] .accordion-body {
  color: #eee;
  background-color: #010101;
  border-color: #404040;
}

[data-theme="dark"] .accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
/*# sourceMappingURL=darktheme_v2.0.2.css.map */