.tableFixHead {
  overflow-y: auto;
  max-height: 85vh;
}


.tableFixHead thead th {
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
}

.tableFixHead tbody th {
  position: sticky;
  left: 0;
}
